import React, { useEffect, useState } from 'react'

import Card from '../../Card/Card'
import Input from '../../Input/Input'
import Button from '../../Button'
import { showToast, startLoading, stopLoading } from '../../../services/common-service'
import api from '../../../factory/api'
import { generateAuthorizationToken } from '../../../services/utils'
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Switch from '../../Input/Switch'
import { useSharkPlugins } from '../../../contexts/SharkPluginsProvider'

export default function CardEditConnection({
    instance,
    setInstance,
    appInfo,
    user,
    setView,
    db_data
}) {
    const { t, i18n } = useTranslation();
    const { appType } = useSharkPlugins()

    const [inputUser, setInputUser] = useState()
    const [inputPassword, setInputPassword] = useState()
    const [inputHost, setInputHost] = useState()
    const [inputName, setInputName] = useState()
    const [inputPort, setInputPort] = useState()
    const [inputSSL, setInputSSL] = useState(false)

    useEffect(() => {
        setInputUser(db_data.user)
        setInputHost(db_data.host)
        setInputPort(db_data.port)
        setInputName(db_data.name)
        setInputSSL(db_data.ssl)
        // setInputPassword("teste")
    }, [db_data])

    const edit = async (data) => {
        try {
            startLoading()

            const schema = Yup.object().shape({
                name: Yup.string().required(t('forms.required')),
                host: Yup.string().required(t('forms.required')),
                user: Yup.string().required(t('forms.required')),
                password: Yup.string().required(t('forms.required')),
            });
            await schema.validate(data, {
                abortEarly: false,
            });

            let { data: result } = await api.put("/instances", {
                db_data: {
                    ...data,
                    password: btoa(data.password)
                }
            }, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            setInstance(result.data)

            setView("instance")

            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: t('messages.updated')
            });
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    return (
        <Card className="min-h-18">
            <div className="overflow-auto">
                <div className="flex justify-between">
                    <h4 className="f4 mt0 mb3">
                        Editar Conexão
                    </h4>
                </div>

                <div>
                    <Input
                        name="name"
                        label="database name"
                        placeholder="name"
                        value={inputName}
                        onChange={e => setInputName(e.target.value)}
                    />
                </div>

                <div className="mt2">
                    <Input
                        name="host"
                        label="host"
                        placeholder="host"
                        value={inputHost}
                        onChange={e => setInputHost(e.target.value)}
                    />
                </div>

                {["postgresql", "mysql"].includes(appType) &&
                    <div className="mt2">
                        <Input
                            name="port"
                            label="port"
                            placeholder="port"
                            value={inputPort}
                            onChange={e => setInputPort(e.target.value)}
                        />
                    </div>
                }

                <div className="mt2">
                    <Input
                        name="user"
                        label="user"
                        placeholder="user"
                        value={inputUser}
                        onChange={e => setInputUser(e.target.value)}
                    />
                </div>

                <div className="mt2">
                    <Input
                        name="password"
                        label="password"
                        placeholder="password"
                        value={inputPassword}
                        onChange={e => setInputPassword(e.target.value)}
                    />
                </div>

                {["postgresql"].includes(appType) &&
                    <div className="mt2">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <p>SSL</p>
                            <div>
                                <Switch
                                    checked={inputSSL}
                                    name={"ssl"}
                                    onChange={value => setInputSSL(value)}
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className="w-100">
                    <div className="mt2 flex" style={{ gap: 10 }}>
                        <Button
                            variant={"delete"}
                            text={"Cancelar"}
                            onClick={() => setView("instance")}
                        />

                        <Button
                            text={t('button.connect')}
                            onClick={() => edit({
                                name: inputName,
                                user: inputUser,
                                host: inputHost,
                                port: inputPort,
                                password: inputPassword,
                                ...(["postgresql", "mysql"].includes(appType) ? {
                                    ssl: inputSSL
                                } : {})
                            })}
                        />
                    </div>
                </div>
            </div>
        </Card>
    )
}