import React from 'react'
import Card from '../../Card/Card'
import { useTranslation } from 'react-i18next';
import { useSharkPlugins } from '../../../contexts/SharkPluginsProvider';

export default function CardApresentation() {
    const { t, i18n } = useTranslation();
    const { appType } = useSharkPlugins()

    return (
        <Card className="min-h-18">
            <div style={{ textAlign: 'center' }}>
                <img
                    src={{
                        mongodb: "https://webimages.mongodb.com/_com_assets/cms/kuyjf3vea2hg34taa-horizontal_default_slate_blue.svg?auto=format%252Ccompress",
                        postgresql: "https://miro.medium.com/v2/resize:fit:610/1*mMq3Bem9r8ASAn1YwcTbEw.png",
                        mysql: "https://labs.mysql.com/common/logos/mysql-logo.svg?v2"
                    }[appType]}
                    style={{ width: "40%" }}
                />
            </div>

            <p dangerouslySetInnerHTML={{ __html: t(`appresentation_${appType}`) }} />

            <ul>
                {[...Array(50).keys()]
                    .filter((g, i) => t(`guidelines.guideline_${i + 1}`) != `guidelines.guideline_${i + 1}`)
                    .map((g, i) =>
                        <li key={i} style={{ fontSize: 16, lineHeight: 2 }}>{t(`guidelines.guideline_${i + 1}`)}</li>
                    )
                }
            </ul>
        </Card>
    )
}