import React, { useState } from 'react'
import Card from '../../Card/Card'
import api from '../../../factory/api'
import { showToast, startLoading, stopLoading } from '../../../services/common-service'
import { useTranslation } from 'react-i18next';
import { generateAuthorizationToken } from '../../../services/utils';
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types';
import Alert from '../../Alert';
import Input from '../../Input/Input';
import Button from '../../Button';
import CardInformation from './CardInformation';
import CardEditConnection from '../CardEditConnection';

export default function CardInstance({
    instance,
    setInstance,
    appInfo,
    user
}) {
    const { t, i18n } = useTranslation();

    const [view, setView] = useState("instance")

    return (
        <>
            {view == "edit" ?
                <CardEditConnection
                    instance={instance}
                    setInstance={setInstance}
                    appInfo={appInfo}
                    user={user}
                    setView={setView}
                    db_data={instance?.db_data || {}}
                /> :
                <CardInformation
                    instance={instance}
                    setInstance={setInstance}
                    appInfo={appInfo}
                    setView={setView}
                />
            }
        </>
    )
}