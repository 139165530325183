import React, { useState } from 'react'

import Card from '../../Card/Card'
import Input from '../../Input/Input'
import Button from '../../Button'
import { showToast, startLoading, stopLoading } from '../../../services/common-service'
import api from '../../../factory/api'
import { generateAuthorizationToken } from '../../../services/utils'
import BlipPortalToastTypes from '../../../constants/blip-portal-toast-types'
import { useTranslation } from 'react-i18next'
import { useSharkPlugins } from '../../../contexts/SharkPluginsProvider'
import Switch from '../../Input/Switch'

export default function CardConnect({
    instance,
    setInstance,
    appInfo
}) {
    const { t, i18n } = useTranslation();
    const { appType } = useSharkPlugins()

    const [inputUser, setInputUser] = useState()
    const [inputPassword, setInputPassword] = useState()
    const [inputHost, setInputHost] = useState()
    const [inputPort, setInputPort] = useState()
    const [inputName, setInputName] = useState()
    const [inputSSL, setInputSSL] = useState()

    const connect = async ({
        name,
        host,
        port,
        user,
        password,
        ssl
    }) => {
        try {
            startLoading()

            let { data } = await api.post("/instances", {
                type: {
                    mongodb: "mongodb",
                    postgresql: "postgresql",
                    mysql: "mysql"
                }[appType],
                db_data: {
                    name,
                    host,
                    port,
                    user,
                    password: btoa(password),
                    ssl
                }
            }, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            setInstance(data.data)

            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: t('instance.created')
            });
        } catch (error) {
            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    return (
        <Card className="min-h-18">
            <div className="overflow-auto">
                <h4 className="f4 mt0 mb3">
                    {t('title.connectDatabase')}
                </h4>
                <p className="f6 mt0 mb3">
                    {t('title.description')}
                </p>

                <div>
                    <Input
                        name="name"
                        label="database name"
                        placeholder="name"
                        value={inputName}
                        onChange={e => setInputName(e.target.value)}
                    />
                </div>

                <div className="mt2">
                    <Input
                        name="host"
                        label="host"
                        placeholder="host"
                        value={inputHost}
                        onChange={e => setInputHost(e.target.value)}
                    />
                </div>

                {["postgresql", "mysql"].includes(appType) &&
                    <div className="mt2">
                        <Input
                            name="port"
                            label="port"
                            placeholder="port"
                            value={inputPort}
                            onChange={e => setInputPort(e.target.value)}
                        />
                    </div>
                }

                <div className="mt2">
                    <Input
                        name="user"
                        label="user"
                        placeholder="user"
                        value={inputUser}
                        onChange={e => setInputUser(e.target.value)}
                    />
                </div>

                <div className="mt2">
                    <Input
                        name="password"
                        label="password"
                        placeholder="password"
                        value={inputPassword}
                        onChange={e => setInputPassword(e.target.value)}
                    />
                </div>

                {["postgresql"].includes(appType) &&
                    <div className="mt2">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <p>SSL</p>
                            <div>
                                <Switch
                                    checked={inputSSL}
                                    name={"ssl"}
                                    onChange={value => setInputSSL(value)}
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className="w-100">
                    <div className="mt2">
                        <Button
                            text={t('button.connect')}
                            onClick={() => connect({
                                name: inputName,
                                user: inputUser,
                                host: inputHost,
                                port: inputPort,
                                password: inputPassword,
                                ssl: inputSSL
                            })}
                        />
                    </div>
                </div>
            </div>
        </Card>
    )
}