import React, { useRef, useEffect } from 'react'

export default function Switch({
    name,
    size = "standard",
    onChange,
    checked,
    disabled
}) {
    const ref = useRef()

    const handleClick = async () => {
        let checked = await ref.current.getValue()

        if (onChange)
            onChange(checked)
    }

    return (
        <bds-switch
            name={name}
            checked={checked}
            size={size}
            ref={ref}
            onClick={handleClick}
            disabled={disabled}
        />
    )
}