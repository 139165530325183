import React, { useEffect, useState } from 'react'
import CommandRow from '../CommandRow'
import './styles.scss';
import Card from '../Card/Card';
import api from '../../factory/api';
import { generateAuthorizationToken } from '../../services/utils';

export default function CommandsList({
    appInfo,
    user
}) {
    const [commands, setCommands] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [totalSize, setTotalSize] = useState(0)
    const [pageSize, setPageSize] = useState(0)
    const [page, setPage] = useState(1)
    var pg = 2

    useEffect(() => {
        getData({ page: 1 })

        window.addEventListener('bdsPaginationChange', (event) => {
            getData({ page: event.detail })
        });
    }, [])

    const getData = async ({
        page
    }) => {
        try {
            setIsLoading(() => true)

            let { data: result } = await api.get("/commands", {
                params: {
                    page
                },
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            let { success, data, totalSize, pageSize } = result

            if (!success)
                throw "Erro"

            setTotalSize(() => totalSize)
            setPageSize(() => pageSize)
            setCommands(() => data)
            setPage(() => page)
        } catch (error) {

        } finally {
            setIsLoading(() => false)
        }
    }

    return (
        <div>
            <Card className="min-h-18">
                <div className="overflow-auto">
                    <div className="flex justify-between">
                        <div>
                            <h4 style={{ marginBottom: 5 }} className="f4 mt0 mb3">Comandos</h4>
                            <p style={{ margin: 0 }}>Total: {totalSize}</p>
                        </div>
                        <div style={{ padding: 0 }}>
                            <bds-button-icon
                                icon="refresh"
                                variant="secondary"
                                disabled={isLoading}
                                bds-loading={isLoading}
                                loading={isLoading}
                                onClick={() => getData({ page })}
                            />
                        </div>
                    </div>
                    <table className="w-100">
                        <tbody>
                            {commands.map(command => <CommandRow
                                key={command._id}
                                {...command}
                            />)}
                        </tbody>
                    </table>
                </div>
            </Card>

            <div style={{ marginTop: 20, textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <bds-pagination
                    pages={pageSize ? Math.ceil(+(totalSize / pageSize)) : 1}
                    started-page={page}
                />
            </div>
        </div>
    )
}