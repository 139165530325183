import React from 'react'

export default function Alert({
    text,
    type = "success"
}) {
    const types = {
        success: { backgroundColor: "#167347", color: "white" },
        error: { backgroundColor: "tomato", color: "white" },
        dark: { backgroundColor: "#1E283B", color: "white" },
    }

    return (
        <div style={{ backgroundColor: types[type]?.backgroundColor, padding: 10, borderRadius: 8 }}>
            {typeof text == "string" ?
                <p style={{ color: types[type]?.color, margin: 0, fontSize: 16 }}>{text}</p>
                :
                text
            }
        </div>
    )
}