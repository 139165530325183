import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import RoutesPath from '../../constants/routes-path';

import { showToast, startLoading, stopLoading } from '../../services/common-service'

import settings from '../../config';

import Header from './components/Header';
import api from '../../factory/api';
import { getApplicationDataAsync } from '../../services/application-service';
import { getLoggedUserAsync } from '../../services/user-service';
import CardInstance from '../../components/cards/CardInstance';
import { generateAuthorizationToken } from '../../services/utils';
import CardConnect from '../../components/cards/CardConnect';
import CardApresentation from '../../components/cards/CardApresentation';
import Copyright from '../../components/Copyright';
import CardToken from '../../components/CardToken';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import ErrorLoad from '../../components/ErrorLoad';
import CommandsList from '../../components/CommandsList';
import { useSharkPlugins } from '../../contexts/SharkPluginsProvider';

const PAGE_ICON = 'plugin';
const BLANK = '_blank';

const Home = () => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { appType, appName } = useSharkPlugins();

    const [appInfo, setAppInfo] = useState({});
    const [user, setUser] = useState({});
    const [language, setLanguage] = useState('');

    const [isLoading, setIsLoading] = useState(true)

    const [instance, setInstance] = useState(null)

    const [error, setError] = useState(null)

    // const { data } = useFetch(`/instances`);

    useEffect(() => {
        // if (appInfo != {} && user != {})
        if (appInfo?.tenantId && user?.culture)
            getInstance()
    }, [appInfo, user])

    useEffect(() => {
        getAppDataAsync()
    }, [])

    const handleNavigation = useCallback(
        (path, params = {}) => {
            history.push(path, params);
        },
        [history]
    );

    const getAppDataAsync = async () => {
        const app = await getApplicationDataAsync();
        setAppInfo(app);

        console.log("appInfo", app);

        const loggedUser = await getLoggedUserAsync();
        setUser(loggedUser);
        console.log("loggedUser", loggedUser)

        setLanguage(i18n.language);
    };

    const getInstance = async () => {
        try {
            console.log("getInstance", {
                appInfo,
                user
            })
            startLoading()

            let { data } = await api.get("/instances", {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo),
                    tenantId: appInfo.tenantId,
                    language: user.culture
                }
            })

            if (!data.success)
                throw data

            setInstance(data.data)
        } catch (error) {
            if (error?.success == false)
                setError(() => error)

            showToast({
                type: BlipPortalToastTypes.DANGER,
                message: error?.data?.message || error?.message || "Ocorreu um erro"
            });
        } finally {
            stopLoading()
        }
    }

    return (
        <div className="ph1 ph4-m ph5-ns pb5">
            <Header
                title={appName}
                icon={PAGE_ICON}
                onClick={() => window.open({
                    mongodb: "https://docs.sharkdev.com.br/blip/mongodbforblip",
                    postgresql: "https://docs.sharkdev.com.br/blip/postgresqlforblip",
                    mysql: "https://docs.sharkdev.com.br/blip/mysqlforblip"
                }[appType], BLANK)}
            />
            {error ?
                <ErrorLoad {...error} /> :
                <div className="flex" style={{ gap: 20 }}>
                    <div className="w-50 m0 mb3 mb4-l mt4">
                        {instance ?
                            <CardInstance
                                instance={instance}
                                setInstance={setInstance}
                                appInfo={appInfo}
                                user={user}
                            /> :
                            <CardConnect
                                instance={instance}
                                setInstance={setInstance}
                                appInfo={appInfo}
                            />
                        }

                        {instance &&
                            <CardToken
                                appInfo={appInfo}
                            />
                        }
                    </div>

                    <div className="w-50 m0 mb3 mb4-l mt4">
                        {instance ?
                            <CommandsList
                                appInfo={appInfo}
                                user={user}
                            /> :
                            <CardApresentation />
                        }
                    </div>
                </div>
            }

            <Copyright />

            {/* <div className="flex flex-column items-center justify-center bp-c-neutral-dark-city f5 h-100 mt4">
                <img src={logo} className="App-logo" alt="logo" />
                <p className="tc">
                    {t('paragraph.homeDescription.part1')}
                    <br />
                    <Trans i18nKey="paragraph.homeDescription.part2">
                        Edit <code>src/pages/Home.js</code> and save to reload.
                    </Trans>
                </p>
                <h5 className="f5 b mt3 mb2">{t('title.exemples')}</h5>
                <span
                    className="f6 flex items-center blue no-underline underline-hover pointer"
                    data-testid="exemple-one"
                    aria-hidden="true"
                    onClick={() =>
                        handleNavigation(RoutesPath.EXAMPLE_PAGE.PATH, {
                            type: 'storedData'
                        })
                    }
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {t('link.blipDataExample')}
                </span>
                <span
                    className="f6 flex items-center blue no-underline underline-hover mt1 pointer"
                    data-testid="exemple-two"
                    aria-hidden="true"
                    onClick={() =>
                        handleNavigation(RoutesPath.EXAMPLE_PAGE.PATH, {
                            type: 'swrCall'
                        })
                    }
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {t('link.swrExemple')}
                </span>
                <span
                    className="f6 flex items-center blue no-underline underline-hover mt1 pointer"
                    data-testid="exemple-two"
                    aria-hidden="true"
                    onClick={() =>
                        handleNavigation(RoutesPath.SETTINGS_PAGE.PATH, {
                            type: 'swrCall'
                        })
                    }
                >
                    <bds-icon name="file-txt-1" size="x-small" />
                    {t('link.pluginSettings')}
                </span>
            </div> */}
        </div >
    );
};

Home.propTypes = {};

export default Home;
