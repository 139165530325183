const translations = {
    title: {
        homePage: 'MongoDB for Blip',
        exemples: 'Ejemplos',
        systemInfo: 'Información del sistema',
        userInfo: 'Información del usuario',
        connectDatabase: 'Conectar Base de Datos',
    },
    paragraph: {
        homeDescription: {
            part1: 'Esta es una plantilla de complemento básica',
            part2: 'Edite <1>src/pages/Home.js</1> y guarde para recargar.'
        }
    },
    link: {
        blipDataExample: 'Obtener datos de blip',
        swrExemple: 'Obtener datos con SWR'
    },
    button: {
        repository: 'Visite el Repositorio',
        connect: 'Conectar',
    },
    menu: {
        goBack: 'Volver'
    },
    name: 'Nombre',
    shortName: 'Nombre corto',
    email: 'E-mail',
    phone: 'Teléfono',
    identity: 'Identificador',
    identifier: 'Identificador',
    language: 'Idioma',
    accessKey: 'Llave de acceso',
    template: 'Plantilla',
    creationDate: 'Fecha de creación',
    instance: {
        connection: {
            connected: "Base de dados conectada",
            disconnected: "Base de dados desconectada",
            failed: "Falló la conexión a la base de datos",
        }
    }
};

export default translations;
