import React, { useState } from 'react'
import Card from '../../../Card/Card'
import api from '../../../../factory/api'
import { showToast, startLoading, stopLoading } from '../../../../services/common-service'
import { useTranslation } from 'react-i18next';
import { generateAuthorizationToken } from '../../../../services/utils';
import BlipPortalToastTypes from '../../../../constants/blip-portal-toast-types';
import Alert from '../../../Alert';
import Input from '../../../Input/Input';
import Button from '../../../Button';
import Switch from '../../../Input/Switch';
import { useSharkPlugins } from '../../../../contexts/SharkPluginsProvider';

export default function CardInformation({
    instance,
    setInstance,
    appInfo,
    setView
}) {
    const { t, i18n } = useTranslation();
    const { appType } = useSharkPlugins()

    const disconnect = async () => {
        try {
            startLoading()

            let { data } = await api.post("/instances/action/disconnect", {}, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo)
                }
            })

            setInstance(data.data)

            showToast({
                type: BlipPortalToastTypes.SUCCESS,
                message: t('messages.disconnected')
            });
        } catch (error) {
            throw error
        } finally {
            stopLoading()
        }
    }

    const reconnect = async () => {
        try {
            startLoading()

            let { data } = await api.post("/instances/action/reconnect", {}, {
                headers: {
                    Authorization: generateAuthorizationToken(appInfo)
                }
            })

            setInstance(data.data)

            showToast(data?.data?.connection == 'connected' ? {
                type: BlipPortalToastTypes.SUCCESS,
                message: t('messages.connected')
            } : {
                type: BlipPortalToastTypes.DANGER,
                title: t('messages.failed_connection'),
                message: data?.data?.connectionMessage
            });
        } catch (error) {
            throw error
        } finally {
            stopLoading()
        }
    }

    return (
        <Card className="min-h-18">
            <div className="overflow-auto">
                <div className="flex justify-between">
                    <h4 className="f4 mt0 mb3">
                        {instance?.type_text}
                    </h4>
                </div>
                <p className="f6 mt0 mb3" onClick={() =>
                    showToast({
                        type: BlipPortalToastTypes.SUCCESS,
                        message: t('success.connect')
                    })}>

                    {instance?.connection == "connected" ?
                        <Alert
                            text={t(`instance.connection.${instance.connection}`)}
                            type={{
                                connected: "success",
                                disconnected: "dark",
                                failed: "error"
                            }[instance.connection]}
                        />
                        :
                        <Alert
                            text={<>
                                <b style={{ color: "white", margin: 0, fontSize: 16 }}>{t("instance.connection." + instance.connection)}</b>
                                {(instance?.connectionMessage && typeof instance?.connectionMessage == "string") && <p style={{ color: "white", margin: 0, fontSize: 16, marginTop: 5 }}>{instance?.connectionMessage}</p>}
                            </>}
                            type={{
                                connected: "success",
                                disconnected: "dark",
                                failed: "error"
                            }[instance.connection]}
                        />
                    }
                </p>

                <div>
                    <Input
                        name="name"
                        label="database name"
                        placeholder="name"
                        value={instance?.db_data?.name}
                        disabled={true}
                    />
                </div>

                <div className="mt2">
                    <Input
                        name="host"
                        label="host"
                        placeholder="host"
                        value={instance?.db_data?.host}
                        disabled={true}
                    />
                </div>

                {["postgresql", "mysql"].includes(appType) &&
                    <div className="mt2">
                        <Input
                            name="port"
                            label="port"
                            placeholder="port"
                            value={instance?.db_data?.port}
                            disabled={true}
                        />
                    </div>
                }

                <div className="mt2">
                    <Input
                        name="user"
                        label="user"
                        placeholder="user"
                        value={instance?.db_data?.user}
                        disabled={true}
                    />
                </div>

                {["postgresql"].includes(appType) &&
                    <div className="mt2">
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <p>SSL</p>
                            <div>
                                <Switch
                                    checked={instance?.db_data?.ssl || false}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                }

                <div className="w-100">
                    <div className="mt2 flex" style={{ gap: 10, justifyContent: "space-between" }}>
                        <div className="flex" style={{ gap: 10 }}>
                            {["connected", "failed"].includes(instance.connection) &&
                                <Button
                                    variant={"delete"}
                                    text={t('button.disconnect')}
                                    onClick={disconnect}
                                />
                            }

                            {["disconnected", "failed"].includes(instance.connection) &&
                                <Button
                                    text={t('button.reconnect')}
                                    onClick={reconnect}
                                />
                            }
                        </div>

                        <div>
                            <Button
                                text={"Editar dados"}
                                onClick={() => setView("edit")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}