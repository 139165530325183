import React from 'react'
import Card from '../Card/Card'
import { generateAuthorizationToken } from '../../services/utils'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showToast } from '../../services/common-service';
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import { useSharkPlugins } from '../../contexts/SharkPluginsProvider';

export default function CardToken({
    appInfo
}) {
    const { t } = useTranslation();
    const { appType } = useSharkPlugins()

    const address = {
        mongodb: "https://mongoforblip.blip.sharkdev.com.br/commands",
        postgresql: "https://postgresqlforblip.blip.sharkdev.com.br/query",
        mysql: "https://mysqlforblip.blip.sharkdev.com.br/query"
    }[appType]

    return (
        <Card className="min-h-18 mt3">
            <div className="overflow-auto">
                <div className="flex">
                    <p className="mb3 bp-fs-7 fw7">
                        Cabeçalho de autenticação (Authorization)
                    </p>
                </div>

                <div>
                    <CopyToClipboard
                        text={generateAuthorizationToken(appInfo)}
                        onCopy={() =>
                            showToast({
                                type: BlipPortalToastTypes.SUCCESS,
                                title: t('messages.copy_to_clipboard'),
                                // message: t('messages.copy_to_clipboard')
                            })
                        }
                    >
                        <div style={{ borderRadius: 15, display: 'flex', backgroundColor: "#DBF1F3", padding: "10px 20px", cursor: "pointer" }}>
                            <input style={{ flex: 1, margin: 0, color: "#15afb2", color: "#15afb2", backgroundColor: "transparent", border: 0, cursor: "pointer" }} disabled={true} value={generateAuthorizationToken(appInfo)} />

                            <div>

                            </div>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>

            <div className="overflow-auto mt3">
                <div className="flex">
                    <p className="mb3 bp-fs-7 fw7">
                        Endereço para requisição
                    </p>
                </div>

                <div>
                    <CopyToClipboard
                        text={address}
                        onCopy={() =>
                            showToast({
                                type: BlipPortalToastTypes.SUCCESS,
                                title: t('messages.copy_to_clipboard'),
                                // message: t('messages.copy_to_clipboard')
                            })
                        }
                    >
                        <div style={{ borderRadius: 15, display: 'flex', backgroundColor: "#DBF1F3", padding: "10px 20px", cursor: "pointer" }}>
                            <input style={{ flex: 1, margin: 0, color: "#15afb2", color: "#15afb2", backgroundColor: "transparent", border: 0, cursor: "pointer" }} disabled={true} value={address} />

                            <div>

                            </div>
                        </div>
                    </CopyToClipboard>
                </div>
            </div>
        </Card>
    )
}