import React, { useState } from 'react'
import moment from 'moment'
import { ObjectView } from 'react-object-view'
import './styles.scss'

export default function CommandRow({
    created_at,
    result,
    body
}) {
    const [opened, setOpened] = useState(false)

    return (
        <>
            <tr>
                <td>
                    <p className="p-result" result={result}>{result}</p>
                </td>
                <td>
                    <p>{moment(created_at).format("DD/MM/YYYY HH:mm:ss")}</p>
                </td>
                <td>
                    <div className="icon-list">
                        <a className="link" onClick={() => setOpened(opened => !opened)}>{opened ? "Fechar" : "Ver"}</a>
                    </div>
                </td>
            </tr >
            {opened &&
                <tr>
                    <td colspan={3}>
                        <div className="object-view-base">
                            <ObjectView
                                data={Array.isArray(body) ? body.length == 1 ? body[0] : body : body}
                            // options={options}
                            // styles={styles}
                            // palette={palette}
                            />
                        </div>
                    </td>
                </tr>
            }
        </>
    )
}