const translations = {
    title: {
        homePage: 'MongoDB for Blip',
        exemples: 'Exemplos',
        systemInfo: 'Informações do sistema',
        userInfo: 'Informações do usuário',
        connectDatabase: 'Conectar Banco de Dados',
        description: 'Utilize o formulário abaixo para informar os dados de conexão do banco de dados.',
    },
    paragraph: {
        homeDescription: {
            part1: 'Este é um modelo de plugin básico',
            part2: 'Edite <1>src/pages/Home.js</1> e salve para recarregar.'
        }
    },
    link: {
        blipDataExample: 'Obtenha dados do blip',
        swrExemple: 'Buscar dados com SWR',
        pluginSettings: 'Configurações'
    },
    button: {
        repository: 'Visite o Repositório',
        connect: 'Conectar',
        disconnect: 'Desconectar',
        reconnect: 'Reconectar',
        document: 'Visite a Documentação'
    },
    menu: {
        goBack: 'Voltar'
    },
    name: 'Nome',
    shortName: 'Nome abreviado',
    email: 'E-mail',
    phone: 'Telefone',
    identity: 'Identificador',
    identifier: 'Identificador',
    language: 'Idioma',
    accessKey: 'Chave de acesso',
    template: 'Template',
    creationDate: 'Data de criação',
    appresentation_mongodb: `<b>MongoDB for Blip</b> chegou para facilitar a vida do dev que precisa se conectar a um banco Mongo. Nada de API intermediária! Agora você pode contar com <b>MongoDB for Blip</b> 😍<br /><br />Com esta extensão, você conecta o Blip ao seu banco de dados MongoDB informando os dados de conexão e já pode sair usando no Builder.<br /><br />É fácil e rápido!`,
    appresentation_postgresql: `<b>PostgreSQL for Blip</b> chegou para facilitar a vida do dev que precisa se conectar a um banco PostgreSQL. Nada de API intermediária! Agora você pode contar com <b>PostgreSQL for Blip</b> 😍<br /><br />Com esta extensão, você conecta o Blip ao seu banco de dados PostgreSQL informando os dados de conexão e já pode sair usando no Builder.<br /><br />É fácil e rápido!`,
    appresentation_mysql: `<b>MySQL for Blip</b> chegou para facilitar a vida do dev que precisa se conectar a um banco MySQL. Nada de API intermediária! Agora você pode contar com <b>MySQL for Blip</b> 😍<br /><br />Com esta extensão, você conecta o Blip ao seu banco de dados MySQL informando os dados de conexão e já pode sair usando no Builder.<br /><br />É fácil e rápido!`,
    guidelines: {
        // guideline_1: "Teste 1",
        // guideline_2: "Teste 2",
    },
    instance: {
        connection: {
            connected: "Banco de dados conectado",
            disconnected: "Banco de dados desconectado",
            failed: "A conexão com o Banco de Dados falhou",
        }
    },
    messages: {
        created: "Instância criada com sucesso",
        updated: "Instância atualizada com sucesso",
        connected: "Instância conectada com sucesso",
        disconnected: "Instância desconectada com sucesso",
        failed_connection: "A conexão com o Banco de Dados falhou",
        copy_to_clipboard: "Copiado para a área de transferência!",
        user_admin: "Apenas administradores podem acessar a extensão"
    },
    forms: {
        required: "Campo obrigatório"
    }
};

export default translations;
